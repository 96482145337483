/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Seo from "../components/seo"
import Footer from "./footer"
import { Helmet } from "react-helmet"
import { setConfiguration } from "react-grid-system"
import { LanguageContext, languages } from "../locales/langContext"
import { LanguageSwitcherContext } from "../locales/languageSwitcherContext"
import { Box } from "theme-ui"

setConfiguration({ maxScreenClass: "xxl", gutterWidth: 68 })

const Layout = ({ children, description, lang, meta, title, i18nPaths }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          async
          src="//l.getsitecontrol.com/zwxe95y4.js"
        ></script>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Seo lang={lang} description={description} meta={meta} title={title} />
      <LanguageContext.Provider value={languages[lang]}>
        <LanguageSwitcherContext.Provider
          value={{ activeLocale: lang, paths: i18nPaths || [] }}
        >
          <Main>{children}</Main>
          <Footer lang={lang} />
        </LanguageSwitcherContext.Provider>
      </LanguageContext.Provider>
    </>
  )
}

const Main = styled.main``

export default Layout
