import React from "react"
import styled from "styled-components"
import { Row, Col } from "react-grid-system"
import { Display, Lead } from "../typography"
import titleBorder from "../../images/title-border.svg"
import Img from "gatsby-image"
import {
  Box,
  Flex,
  Container,
  Text,
  Image,
  Heading,
} from "@theme-ui/components"
import DesignTokens from "../designTokens"
import { OutboundLink } from "../utils/link"
import LanguageSwitcher from "../languageSwitcher"
import Phone from "../../images/phone.svg"
import Mail from "../../images/mail.svg"
import Mark from "../../images/mark.svg"
import Logo from "../../images/gavazzi-logo.png"

const LandingHeroHeader = ({ title, subtitle, heroImage }) => (
  <>
    <Box sx={{ position: "relative" }}>
      <Container sx={{ py: [1, 2] }}>
        <Flex
          sx={{
            flexDirection: ["column", "row"],
            justifyContent: "space-between",
            a: {
              textDecoration: "none",
            },
          }}
        >
          <Flex sx={{ flexDirection: ["column", "row"] }}>
            <Flex
              sx={{
                fontSize: 1,
                pt: [2, 0],
                pb: [2, 0],
                justifyContent: ["center", "start"],
                alignItems: ["start", "center"],
              }}
            >
              <Box sx={{ pr: [1] }}>
                <Image src={Phone} />
              </Box>
              <OutboundLink
                href="tel:+410712792035"
                target="_blank"
                rel="nofollow noopener"
              >
                <p>Tel. +41 (0)71 279 20 35</p>
              </OutboundLink>
            </Flex>
            <Flex
              sx={{
                fontSize: 1,
                pl: [0, 5],
                pb: [2, 0],
                justifyContent: ["center", "start"],
                alignItems: "center",
              }}
            >
              <Box sx={{ pr: [1] }}>
                <Image src={Mail} />
              </Box>
              <OutboundLink
                href="mailto:info@gavazzitrading.ch"
                target="_blank"
                rel="nofollow noopener"
              >
                info@gavazzitrading.ch
              </OutboundLink>
            </Flex>
            <Flex
              sx={{
                fontSize: 1,
                display: ["none", "flex"],
                pl: [0, 5],
                pb: [1, 0],
                alignItems: "center",
              }}
            >
              <Box sx={{ pr: [1] }}>
                <Image src={Mark} />
              </Box>
              <Text sx={{ m: 0, fontSize: 1, color: "primary" }}>
                Breitistrasse 3 - 5610 Wohlen Svizzera
              </Text>
            </Flex>
          </Flex>
          <Box sx={{ display: ["none", "flex"] }}>
            <LanguageSwitcher />
          </Box>
        </Flex>
      </Container>
    </Box>
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        backgroundColor: "primary",
        paddingTop: "2rem",
        paddingBottom: ["5rem", "18rem"],
      }}
    >
      <CustomBackground>
        <Img fluid={heroImage.fluid} />
        <Gradient />
      </CustomBackground>
      <Container>
        <Box sx={{ position: "relative", zIndex: 9, pb: [6, 8] }}>
          <Image
            sx={{
              maxWidth: ["125px", "270px"],
            }}
            src={Logo}
          />
        </Box>

        <Row>
          <Col>
            <StyledBox>
              <Box sx={{ maxWidth: "750px" }}>
                <Display as="h1" color="white">
                  <Box sx={{ hyphens: ["auto", "auto", "manual"], mb: [4] }}>
                    {title}
                  </Box>
                </Display>
                {subtitle && (
                  <Text
                    as="h2"
                    color="white"
                    variant="h3"
                    sx={{
                      textTransform: "uppercase",
                      fontSize: [4, 5],
                    }}
                  >
                    {subtitle}
                  </Text>
                )}
              </Box>
            </StyledBox>
          </Col>
        </Row>
      </Container>
    </Box>
  </>
)

const CustomBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  .gatsby-image-wrapper {
    height: 100%;
  }
`

const CustomLead = styled(Lead)`
  text-transform: uppercase;
  font-size: 32px;
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

const StyledBox = styled.div`
  padding-top: 3rem;
  background-image: url(${titleBorder});
  background-repeat: no-repeat;
  width: 70%;
  @media screen and (max-width: 1280px) {
    width: 100%;
  }
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default LandingHeroHeader
