import React from "react"

export const languages = {
  it: {
    locale: "it",
    generalRepresentative: "Rappresentante Generale",
    applications: "applicazioni",
    company: "azienda",
    products: "prodotti",
    search: "Cerca",
    results: "risultati",
    noResults: "nessun risultato",
    discoverMore: "Scopri di più",
    legalAddress: "Sede Legale",
    shareCapital: "Capitale sociale",
    registrationNumber: "Numero Iscrizione",
    vatCode: "P. iva e Cod. Fisc.",
    fullName: "Nome e cognome",
    phone: "Telefono",
    message: "Messaggio",
    consent: "Si, acconsento",
    contactUs: "Contattaci",
    contactUsInfo: "Scopri quali soluzioni possiamo offrirti",
    consentPolicy:
      "Dopo aver preso visione della presente informativa sulla privacy ai sensi dell'ART.13 D.Lgs. n.196 del 2003, a) Acconsento al trattamento dei dati personali al fine esclusivo di avere informazioni riguardo i servizi relativi al presente sito web.",
    loading: "Caricamento",
    submit: "Invia",
    submitted: "Richiesta inviata",
    blog: "Blog",
    back: "Indietro",
    noArticle: "Nessun articolo trovato",
    where: "Dove siamo",
    contacts: "Contatti",
    insight: "News",
  },
  en: {
    locale: "en",
    applications: "applications",
    generalRepresentative: "General Representative",
    company: "company",
    products: "products",
    search: "Search",
    results: "results",
    noResults: "no results",
    discoverMore: "Discover more",
    legalAddress: "Legal Head Office",
    shareCapital: "Share capital",
    registrationNumber: "Registration Number",
    vatCode: "VAT Code",
    fullName: "Full name",
    phone: "Phone",
    message: "Message",
    consent: "I agree",
    contactUs: "Contact us",
    contactUsInfo: "Discover the solutions we can offer you",
    consentPolicy:
      "Having read the present Privacy Policy pursuant to ART.13 D.Lgs. n.196 of 2003, a) I hereby give my consent to the processing of my personal data for the exclusive purpose of receiving information related to the services provided by this website.",
    loading: "Loading",
    submit: "Submit",
    submitted: "Submitted",
    blog: "Blog",
    back: "Back",
    noArticle: "No articles found",
    where: "Where we are",
    contacts: "Contacts",
    insight: "News",
  },
  fr: {
    locale: "fr",
    applications: "applications",
    generalRepresentative: "Représentant Général",
    company: "company",
    contactUs: "Nous contacter",
    contactUsInfo: "Découvrez les solutions que nous pouvons vous proposer",
    products: "produits",
    search: "Recherche",
    results: "résultats",
    noResults: "aucun résultat",
    discoverMore: "En savoir plus",
    legalAddress: "Siège social légal",
    shareCapital: "Capital social",
    registrationNumber: "Numéro d'enregistrement",
    vatCode: "Code TVA",
    fullName: "Nom complet",
    phone: "Téléphone",
    message: "Message",
    consent: "J'accepte",
    consentPolicy:
      "Ayant lu la présente politique de confidentialité conformément à l'ART.13 D.Lgs. n.196 de 2003, a) je donne mon accord au traitement de mes données personnelles dans le but exclusif de recevoir des informations relatives aux services fournis par ce site web.",
    loading: "Loading",
    submit: "Submit",
    submitted: "Submitted",
    blog: "Blog",
    back: "Back",
    noArticle: "Aucun article trouvé",
    where: "Où nous sommes",
    contacts: "Contacts",
    insight: "News",
  },
  de: {
    locale: "de",
    applications: "anwendungen",
    generalRepresentative: "Generalvertreter",
    company: "Unternehmen",
    products: "produkte",
    search: "Suche",
    results: "Ergebnisse",
    noResults: "keine Ergebnisse",
    discoverMore: "Mehr entdecken",
    legalAddress: "Legal Head Office",
    shareCapital: "Share capital",
    registrationNumber: "Registration Number",
    vatCode: "Mehrwertsteuer-Code",
    fullName: "Vollständiger Name",
    phone: "Telefon",
    message: "Nachricht",
    consent: "I agree",
    contactUs: "Kontakt",
    contactUsInfo: "Entdecken Sie die Lösungen, die wir Ihnen anbieten können",
    consentPolicy:
      "Nach Kenntnisnahme dieser Datenschutzerklärung gemäß ART.13 D.Lgs. Nr. 196 von 2003 Ich stimme der Verarbeitung personenbezogener Daten zu, um Informationen über die Dienstleistungen dieser Website zu erhalten.",
    loading: "Laden",
    submit: "Einreichen",
    submitted: "Eingereicht",
    blog: "Blog",
    back: "Zurück",
    noArticle: "No articles found",
    where: "Wo wir sind",
    contacts: "Kontakte",
    insight: "Nachrichten",
  },
}

export const LanguageContext = React.createContext(languages.it)
