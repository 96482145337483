import React from "react"
import { Box, Text, Container, Link } from "@theme-ui/components"
import Img from "gatsby-image"
import RichContentTextBlock from "../richContentTextBlock"

const LandingIntroBlock = ({ body, image, callToAction, url }) => {
  return (
    <Box sx={{py:[4,6]}}> 
      <Container
        sx={{
          position: "relative",
        }}
      >
        <Img fluid={image.fluid} />
        <Box
          sx={{
            position: "absolute",
            color: "white",
            bottom: "0rem",
            padding: "2rem 2rem",
            width: "600px",
            backgroundColor: "primary",
            right: "3rem",
            "@media screen and (max-width: 768px)": {
              position: "relative",
              width: "100%",
              left: "0",
              bottom: "0",
            },
            "& > *": {
              marginBottom: "2rem",
              "&:last-child": { marginBottom: "0" },
            },
          }}
        >
          <RichContentTextBlock html={body} />
          <Link
            sx={{
              color: "white",
              textTransform: "uppercase",
              textDecoration: "none",
              paddingLeft: "3rem",
              backgroundImage:
                "linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255))",
              backgroundSize: "36px 1px",
              backgroundPosition: "0px 50%",
              backgroundRepeat: "no-repeat",
            }}
            href={url}
          >
            {callToAction}
          </Link>
        </Box>
      </Container>
    </Box>
  )
}

export default LandingIntroBlock
