import React from "react"
import styled from "styled-components"
import ContactForm from "./contactForm"
import { Box, Image, Container, Heading } from "theme-ui"
import BGImage from "../images/footer-image.jpg"
import { LanguageContext } from "../locales/langContext"

const ContactBlock = () => (
  <Box sx={{ position: "relative", pt: [6] }}>
    <LanguageContext.Consumer>
      {t => (
        <>
          <Container sx={{ pb: [6, 6], zIndex: 1, position: "relative" }}>
            <Heading
              as="h2"
              variant="h2"
              sx={{ color: "primary", fontSize: [4, 6] }}
            >
              {t.contactUs}
            </Heading>
            <Heading
              as="h3"
              variant="h2"
              sx={{ color: "primary", fontSize: [4, 5], fontFamily: "body" }}
            >
              {t.contactUsInfo}
            </Heading>
            <ContactForm />
          </Container>

          <Image
            sx={{
              position: "absolute",
              bottom: "0",
              zIndex: 0,
            }}
            src={BGImage}
          />
        </>
      )}
    </LanguageContext.Consumer>
  </Box>
)

export default ContactBlock
