import React, { useState } from "react"
import { Box, Flex } from "@theme-ui/components"
import { LanguageSwitcherContext } from "../locales/languageSwitcherContext"
import { Link, OutboundLink } from "./utils/link"

const LanguageSwitcher = ({ mobile }) => {
  const [show, setShow] = useState(false)
  return (
    <LanguageSwitcherContext.Consumer>
      {({ activeLocale, paths }) => {
        // console.log(paths)
        const activeLink = paths.find(x => x.locale === activeLocale)
        return (
          <Flex
            as={"ul"}
            sx={{
              flexDirection: "row",
              right: 0,
              margin: 0,
              ml: [0, 3],
              listStyle: "none",
            }}
          >
            {paths
              .sort((a, b) => b.locale.localeCompare(a.locale))
              .reverse()
              .map((link, index) => (
                <Box as="li" key={index} sx={{ ml: [2] }}>
                  <OutboundLink
                    target=""
                    href={link.value}
                    sx={{
                      whiteSpace: "nowrap",
                      display: "block",
                      borderRadius: "xs",
                      fontWeight:
                        activeLocale === link.locale ? "bold" : "normal",
                      textTransform: "uppercase",
                      textDecoration: "none",
                      color: activeLocale === link.locale ? "primary" : "dark",
                      borderRight: index != paths.length - 1 && "1px solid",
                      borderColor: "primary",
                      pr: [2],
                    }}
                  >
                    {link.locale}
                  </OutboundLink>
                </Box>
              ))}
          </Flex>
        )
      }}
    </LanguageSwitcherContext.Consumer>
  )
}

export default LanguageSwitcher
