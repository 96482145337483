import { Box, Text, Container, Grid, Heading, Link } from "@theme-ui/components"
import React from "react"
import RichContentTextBlock from "../richContentTextBlock"
import LandingProductCarousel from "./productCarousel"

const LandingProductCollection = ({
  title,
  subtitle,
  callToAction,
  url,
  products,
}) => {
  return (
    <Box>
      <Container sx={{ pb: [6, 8] }}>
        <Grid columns={["1fr", "1fr 1fr"]} gap={[4, 4]}>
          <Box>
            <Heading
              as="h2"
              variant="h2"
              sx={{
                color: "primary",
                textDecoration: "none",
                paddingLeft: ["3rem","5rem"],
                fontSize: [4, 6],
                backgroundImage: "linear-gradient(90deg, #00793D, #00793D)",
                backgroundSize: ["30px 5px","70px 5px"],
                backgroundPosition: "0px 15%",
                backgroundRepeat: "no-repeat",
              }}
            >
              {title}
            </Heading>
          </Box>
          <Box sx={{ pl: [0,6] }}>
            <RichContentTextBlock html={subtitle} />
            <Box sx={{ pt: [6] }}>
              <Link
                sx={{
                  color: "primary",
                  textTransform: "uppercase",
                  textDecoration: "none",
                  paddingLeft: "3rem",
                  backgroundImage: "linear-gradient(90deg, #00793D, #00793D)",
                  backgroundSize: "36px 1px",
                  backgroundPosition: "0px 50%",
                  backgroundRepeat: "no-repeat",
                }}
                href={url}
              >
                {callToAction}
              </Link>
            </Box>
          </Box>
        </Grid>
      </Container>
      <Box sx={{pb:[6,8]}}>
        <LandingProductCarousel products={products} />
      </Box>
    </Box>
  )
}

export default LandingProductCollection
