import React, { useState } from "react"
import {
  Box,
  Container,
  Text,
  Heading,
  Grid,
  Input,
  Button,
  Flex,
  Link,
  Textarea,
  Checkbox,
  Label,
} from "theme-ui"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui"
import axios from "axios"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { LanguageContext } from "../locales/langContext"

const ContactForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const light = getColor(themeUiTheme, "light")
  const isBrowser = typeof window !== "undefined"

  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    company: "",
    phone: "",
    message: "",
  })

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)

  const onUpdateField = e => {
    const nextFormState = {
      ...formData,
      [e.target.name]: e.target.value,
    }
    setFormData(nextFormState)
  }

  const sendMail = async event => {
    event.preventDefault()

    const result = await executeRecaptcha("dynamicAction")

    setLoading(true)
    const data = formData

    if (result)
      axios
        .post("/.netlify/functions/sendMail", data)
        .then(function (response) {
          setSuccess(true)
          setLoading(false)

          if (typeof window !== "undefined" && window.dataLayer !== undefined) {
            window.dataLayer = window.dataLayer || []

            window.dataLayer.push({
              event: "formSubmission",
              formType: "Contact",
            })
          }
        })
        .catch(function (error) {
          setSuccess(false)
          setLoading(false)
        })
    else {
      setSuccess(false)
      setLoading(false)
    }
  }

  return (
    <Box
      sx={{
        py: [4, 6],
        position: "relative",
        backgroundColor: "trasparent",
      }}
    >
      <LanguageContext.Consumer>
        {t => (
          <>
            <Box name="contactForm">
              <Box>
                <Box as="form" onSubmit={sendMail} sx={{ my: [4] }}>
                  <Box>
                    <Grid
                      sx={{
                        flexWrap: "wrap",
                        flexDirection: "column",
                      }}
                      gap={[0, 0, 0, 0]}
                    >
                      <Flex sx={{ flexWrap: "wrap" }}>
                        <Box
                          sx={{
                            pr: [0, 1, 1, 1],
                            pb: [3, 3, 3, 3],
                            width: ["100%", "50%"],
                          }}
                        >
                          <Label>{t.fullName} *</Label>
                          <Input
                            onChange={e => onUpdateField(e)}
                            name="fullName"
                            variant={"inputs.primary"}
                            required
                          />
                        </Box>
                        <Box
                          sx={{
                            pl: [0, 1, 1, 1],
                            pb: [3, 3, 3, 3],
                            width: ["100%", "50%"],
                          }}
                        >
                          <Label>{t.company} *</Label>
                          <Input
                            onChange={e => onUpdateField(e)}
                            name="company"
                            placeholder=""
                            variant={"inputs.primary"}
                            required
                          />
                        </Box>
                      </Flex>
                      <Flex sx={{ flexWrap: "wrap" }}>
                        <Box
                          sx={{
                            pr: [0, 1, 1, 1],
                            pb: [3, 3, 3, 3],
                            width: ["100%", "50%"],
                          }}
                        >
                          <Label>Email *</Label>
                          <Input
                            onChange={e => onUpdateField(e)}
                            name="email"
                            type="email"
                            placeholder=""
                            variant={"inputs.primary"}
                            required
                          />
                        </Box>
                        <Box
                          sx={{
                            pl: [1, 1, 1, 1],
                            pb: [3, 3, 3, 3],
                            width: ["100%", "50%"],
                          }}
                        >
                          <Label>{t.phone} *</Label>
                          <Input
                            onChange={e => onUpdateField(e)}
                            name="phone"
                            placeholder=""
                            variant={"inputs.primary"}
                            required
                          />
                        </Box>
                      </Flex>
                      <Label>{t.message}</Label>
                      <Textarea
                        sx={{
                          fontFamily: "body",
                          fontSize: [2],
                        }}
                        onChange={e => onUpdateField(e)}
                        name="message"
                        placeholder=""
                        variant={"inputs.primary"}
                        type="textarea"
                      />
                      <LabeledCheckbox
                        checkedCheckbox={e => {
                          setFormData(prevState => ({
                            ...prevState,
                            agreementPrivacyCookies: e,
                          }))
                        }}
                        required={true}
                      >
                        <Box>
                          <Text
                            variant="p"
                            sx={{
                              fontSize: [0],
                              color: "primary",
                              a: {
                                color: "primary",
                              },
                            }}
                            dangerouslySetInnerHTML={{
                              __html: t.consentPolicy,
                            }}
                          />
                        </Box>
                      </LabeledCheckbox>

                      {!success ? (
                        <Box>
                          <Button
                            type="submit"
                            title={
                              !formData.agreementPrivacyCookies
                                ? "Acconsenti al trattamenti dei dati personali"
                                : ""
                            }
                            sx={{
                              borderRadius: "none",
                              px: [4],
                              py: [3],
                              textAlign: "center",
                              cursor: "pointer",
                              mt: [3, 3, 3, 3],
                              opacity: !formData.agreementPrivacyCookies && 0.7,
                            }}
                            variant={"links.primary"}
                          >
                            <Flex
                              sx={{
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              {loading ? t.loading : t.submit}
                            </Flex>
                          </Button>
                        </Box>
                      ) : (
                        <Box>
                          <Button
                            type="submit"
                            sx={{ mt: [3, 3, 3, 3] }}
                            variant="buttons.full.primaryForm"
                            disabled
                          >
                            <Flex
                              sx={{
                                justifyContent: "center",
                                justifyItems: "center",
                                alignItems: "center",
                              }}
                            >
                              {t.submitted}
                            </Flex>
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </Box>
                </Box>
                {success === true ? (
                  <Flex sx={{ maxWidth: "600px" }}>
                    <Heading
                      sx={{ my: [4], color: "primary" }}
                      as="h2"
                      variant="h2"
                    >
                      Richiesta inviata!
                    </Heading>
                  </Flex>
                ) : (
                  success === false && (
                    <Flex sx={{ maxWidth: "600px" }}>
                      <Heading
                        sx={{ my: [4], color: "primary" }}
                        as="h2"
                        variant="h2"
                      >
                        Qualcosa è andato storto
                      </Heading>
                    </Flex>
                  )
                )}
              </Box>
            </Box>
          </>
        )}
      </LanguageContext.Consumer>
    </Box>
  )
}

const LabeledCheckbox = ({
  children,
  defaultChecked,
  checkedCheckbox,
  ...props
}) => {
  const [checked, setChecked] = useState(defaultChecked)

  return (
    <Label
      sx={{
        display: "flex",
        alignItems: "center",
        fontWeight: checked ? "bold" : "normal",
        color: "primary",
        "input:checked~.css-kydphz": {
          color: "primary",
        },
        svg: {
          color: "primary",
        },
        mt: [3, 3, 3, 3],
      }}
    >
      <Checkbox
        sx={{
          color: "primary",
          "input:checked~&": {
            color: "primary",
          },
        }}
        checked={checked}
        onChange={() => {
          checkedCheckbox(!checked)
          setChecked(!checked)
        }}
        {...props}
      />
      {children}
    </Label>
  )
}

export default ContactForm
