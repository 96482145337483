import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { ScreenClassProvider } from "react-grid-system"
import { getHomePath } from "../components/pathUtils"
import LandingHeroHeader from "../components/landing/header"
import LandingIntroBlock from "../components/landing/introBlock"
import LandingProductCollection from "../components/landing/productCollection"
import LandingTextBlock from "../components/landing/textBlock"
import ContactBlock from "../components/contactBlock"

const Page = ({ data: { page, site }, pageContext, location }) => {
  console.log(page)
  const locale = pageContext.locale
  const i18nPaths = page._allContentLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getHomePath(locale.locale),
    }
  })

  return (
    <ScreenClassProvider>
      <Layout
        lang={locale}
        title={page.title}
        location={location}
        i18nPaths={i18nPaths}
      >
        <LandingHeroHeader
          title={page.title}
          subtitle={page.subtitle}
          heroImage={page.heroImage}
        />
        {page.content.map(section => (
          <section key={section.id}>
            {section.model.apiKey === "landing_intro_block" && (
              <LandingIntroBlock
                body={section.body}
                image={section.image}
                callToAction={section.callToAction}
                url={section.url}
              />
            )}
            {section.model.apiKey === "landing_text_block" && (
              <LandingTextBlock
                body={section.body}
                image={section.image}
                callToAction={section.callToAction}
                url={section.url}
              />
            )}
            {section.model.apiKey === "landing_product_collection" && (
              <LandingProductCollection
                title={section.title}
                subtitle={section.subtitle}
                products={section.products}
                callToAction={section.callToAction}
                url={section.url}
              />
            )}
          </section>
        ))}
        <ContactBlock />
      </Layout>
    </ScreenClassProvider>
  )
}

export default Page

export const query = graphql`
  query LandingPageQuery($locale: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsGavazziTrading(locale: { eq: $locale }) {
      id
      title
      subtitle
      locale
      heroImage {
        fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      _allContentLocales {
        locale
      }
      content {
        ... on DatoCmsLandingProductCollection {
          id
          title
          subtitle
          callToAction
          url
          products {
            id
            title
            url
            image {
              fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsLandingIntroBlock {
          id
          body
          callToAction
          url
          image {
            fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsLandingTextBlock {
          id
          body
          image {
            fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          url
          callToAction
          model {
            apiKey
          }
        }
      }
    }
  }
`
