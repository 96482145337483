import React from "react"
import { Box, Text, Container, Grid, Link } from "@theme-ui/components"
import Img from "gatsby-image"
import RichContentTextBlock from "../richContentTextBlock"

const LandingTextBlock = ({ body, image, callToAction, url }) => {
  return (
    <Box
      sx={{
        py: [4,6],
        backgroundColor: "primary",
        color: "light",
        p: {
          color: "light",
        },
      }}
    >
      <Container> 
        <Grid columns={["1fr", "1fr 1fr"]} gap={[4,8]}>
          <Box>
            <Box
              sx={{
                p: { pt: [4], pb: [4] },
                h2: {
                  pt: "0!important",
                  mt: "0!important",
                  fontFamily:"heading"
                },
              }}
            >
              <RichContentTextBlock html={body} />
            </Box>
            <Link
              sx={{
                color: "white",
                textTransform: "uppercase",
                textDecoration: "none",
                paddingLeft: "3rem",
                backgroundImage:
                  "linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255))",
                backgroundSize: "36px 1px",
                backgroundPosition: "0px 50%",
                backgroundRepeat: "no-repeat",
              }}
              href={url}
            >
              {callToAction}
            </Link>
          </Box>
          <Box>
            <Img fluid={image.fluid} />
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default LandingTextBlock
