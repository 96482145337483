import { Box, Text, Container, Flex, Link } from "@theme-ui/components"
import Img from "gatsby-image"
import React, { useEffect, useRef, useState } from "react"

import { Mousewheel, Pagination, Keyboard, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/swiper.min.css"

const LandingProductCarousel = ({ products }) => {
  const customContainerRef = useRef()
  const [marginLeft, setMarginLeft] = useState()

  useEffect(() => {
    setMarginLeft(customContainerRef.current.getBoundingClientRect().left)
    function handleResize() {
      setMarginLeft(customContainerRef.current.getBoundingClientRect().left)
      console.log(customContainerRef.current.getBoundingClientRect())
    }

    window.addEventListener("resize", handleResize)
  }, [])

  return (
    <Box
      sx={{
        ".mySwiper .swiper-wrapper .swiper-slide:first-child": {
          ml: marginLeft,
          pl: [3, 4],
        },
        ".mySwiper .swiper-pagination": {
          ml: [marginLeft + 16, marginLeft + 32],
        },
        ".mySwiper .swiper-wrapper .swiper-slide:last-child": {
          pr: [marginLeft + 16, marginLeft + 32],
        },
        ".mySwiper .swiper-slide": {
          mb: [3, 6],
          height: "auto",
          img: {
            height: "100%",
          },
        },
        ".gatsby-image-wrapper": {
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          height: ["400px", "480px"],
        },
      }}
    >
      <Container
        className="customcontainer"
        ref={customContainerRef}
        sx={{ py: [0, 0] }}
      ></Container>
      <Swiper
        modules={[Pagination, Mousewheel, Keyboard, Autoplay]}
        mousewheel={{
          forceToAxis: true,
          sensitivity: 1,
          releaseOnEdges: true,
        }}
        autoHeight={false}
        className="mySwiper"
        spaceBetween={30}
        slidesPerView={1.4}
        centeredSlides={true}
        centeredSlidesBounds={true}
        onSlideChange={() => console.log("slide change")}
        onSwiper={swiper => console.log(swiper)}
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        loop
      >
        {products.map((product, index) => (
          <SwiperSlide>
            <Box key={product.id}>
              <Link
                href={product.url}
                sx={{
                  textDecoration: "none",
                }}
              >
                <Box sx={{ zIndex: 10, position: "relative" }}>
                  <Img fluid={product.image.fluid} />
                </Box>
                <Flex
                  sx={{ alignItems: "end", zIndex: 9999, position: "relative" }}
                >
                  <Box
                    sx={{
                      display: ["none", "block"],
                      marginTop: "-100px",
                      minWidth: "80px",
                    }}
                  >
                    <Text
                      sx={{
                        textTransform: "uppercase",
                        textDecoration: "none",
                        paddingLeft: ["2rem", "5rem"],
                        backgroundImage:
                          "linear-gradient(90deg, #00793D, #00793D)",
                        backgroundSize: ["30px 5px", "70px 5px"],
                        backgroundPosition: "0px 80%",
                        backgroundRepeat: "no-repeat",
                        transform: "translateY(-50%)",
                        fontSize: ["32px", "160px"],
                        color: "primary",
                        fontFamily: "heading",
                      }}
                    >
                      {index < 10 ? "0" + (index + 1) : ""}
                    </Text>
                  </Box>
                  <Text sx={{ pb: [2] }}>{product.title}</Text>
                </Flex>
              </Link>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

export default LandingProductCarousel
