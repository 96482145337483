import React, { useEffect } from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import { Flex } from "@theme-ui/components"
import DesignTokens from "./designTokens"
import { OutboundLink } from "./utils/link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faYoutube,
  faLinkedin,
  faWizardsOfTheCoast,
} from "@fortawesome/free-brands-svg-icons"
import { LanguageContext } from "../locales/langContext"
import { Link } from "./utils/link"
import LanguageSwitcher from "./languageSwitcher"

const Footer = () => {
  const privacy = {
    it: "https://www.iubenda.com/privacy-policy/78395512",
    en: "https://www.iubenda.com/privacy-policy/16081847",
    de: "https://www.iubenda.com/privacy-policy/58059846",
    fr: "https://www.iubenda.com/privacy-policy/67643055",
  }
  const cookie = {
    it: "https://www.iubenda.com/privacy-policy/78395512/cookie-policy",
    fr: "https://www.iubenda.com/privacy-policy/67643055/cookie-policy",
    en: "https://www.iubenda.com/privacy-policy/16081847/cookie-policy",
    de: "https://www.iubenda.com/privacy-policy/58059846/cookie-policy",
  }

  useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://cdn.iubenda.com/iubenda.js"
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <LanguageContext.Consumer>
      {t => (
        <Wrapper>
          <Container>
            <Row>
              <Col>
                <Box>
                  <Details>
                    <p>
                      <strong>GAVAZZI Trading SA</strong>
                    </p>
                    <p>{t.generalRepresentative}: Mr. Paul Zgraggen</p>
                    <p>Breitistrasse 3</p>
                    <p>5610 Wohlen Svizzera</p>
                    <p>VAT CHE102.215.931</p>
                  </Details>
                  <Details>
                    <OutboundLink
                      href="tel:+410712792035"
                      target="_blank"
                      rel="nofollow noopener"
                    >
                      <p>Tel. +41 (0)71 279 20 35</p>
                    </OutboundLink>
                    <OutboundLink
                      href="tel:+410712792036"
                      target="_blank"
                      rel="nofollow noopener"
                    >
                      <p>Fax +41 (0)71 279 20 36</p>
                    </OutboundLink>
                    <OutboundLink
                      href="tel:+410796002368"
                      target="_blank"
                      rel="nofollow noopener"
                    >
                      <p>Mob. +41 (0)79 600 23 68</p>
                    </OutboundLink>
                    <OutboundLink
                      href="mailto:info@gavazzitrading.ch"
                      target="_blank"
                      rel="nofollow noopener"
                    >
                      info@gavazzitrading.ch
                    </OutboundLink>
                  </Details>
                  <Details>
                    <LanguageSwitcher />
                  </Details>
                  <Details>
                    <Social>
                      <OutboundLink
                        href="https://www.youtube.com/channel/UCuPzAo-s1B1-ANWGoH_9zNg/featured"
                        target="blank"
                        rel="nofollow noopener"
                      >
                        <FontAwesomeIcon icon={faYoutube} size="2x" />
                      </OutboundLink>
                      <OutboundLink
                        href="https://www.linkedin.com/company/gavazzi-tessuti-tecnici-s.p.a.-in-forma-abbreviata-gavazzi-s.p.a./"
                        target="blank"
                        rel="nofollow noopener"
                      >
                        <FontAwesomeIcon icon={faLinkedin} size="2x" />
                      </OutboundLink>
                    </Social>
                  </Details>
                </Box>
              </Col>
            </Row>
            <Row>
              <Col>
                <Box>
                  <Details small>
                    <p>© 2022 GAVAZZI Trading SA</p>
                    <Flex sx={{ a: { mx: 1 } }}>
                      <OutboundLink
                        href={privacy[t.locale]}
                        className="iubenda-nostyle no-brand iubenda-embed"
                        title="Privacy Policy"
                      >
                        Privacy Policy
                      </OutboundLink>
                      <OutboundLink
                        href={cookie[t.locale]}
                        className="iubenda-nostyle no-brand iubenda-embed"
                        title="Cookie Policy"
                      >
                        Cookie Policy
                      </OutboundLink>
                    </Flex>
                  </Details>
                </Box>
              </Col>
            </Row>
          </Container>
        </Wrapper>
      )}
    </LanguageContext.Consumer>
  )
}

const Wrapper = styled.div`
  background: ${DesignTokens.colors.primary[500]};
  color: white;
  padding-top: 3rem;
  padding-bottom: 3rem;
`

const Box = styled.div`
  border-top: 1px solid ${DesignTokens.colors.primary[400]};
  padding-top: 3rem;
  padding-bottom: 3rem;
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Details = styled.div`
  font-size: ${props => (props.small ? "0.785rem" : "1rem")};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  a {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  & > * {
    margin-bottom: 0.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Social = styled.div`
  display: flex;
  flex-direction: row;
  a {
    margin: 0 0.5rem;
  }
`

export default Footer
